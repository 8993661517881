@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  /*border: 1px solid red;*/
}

.home,
.contact,
.projects,
.readings {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.btn {
  padding: 0.8em;
  outline: none;
  border: 1px solid transparent;
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 40%);
  border-radius: 3px;
  color: #fff;
  background-color: #0077cc;
}

.btn:hover {
  cursor: pointer;
  background-color: #0064bd;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

:root {
  --brandColor: 15, 82, 186;
  --secondaryColor: 255, 252, 252;
  --textColor: 17, 17, 17;
}
/*#0033FF - nice blue*/
