@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  /*border: 1px solid red;*/
}

.home,
.contact,
.projects,
.readings {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.btn {
  padding: 0.8em;
  outline: none;
  border: 1px solid transparent;
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 40%);
  border-radius: 3px;
  color: #fff;
  background-color: #0077cc;
}

.btn:hover {
  cursor: pointer;
  background-color: #0064bd;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

:root {
  --brandColor: 15, 82, 186;
  --secondaryColor: 255, 252, 252;
  --textColor: 17, 17, 17;
}
/*#0033FF - nice blue*/

.navbar {
  background: transparent;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.scroll-active {
  background-color: white;
  box-shadow: 0px 2px 2px grey;
  transition: background-color .3s linear;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: rgba(var(--textColor), 0.9);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-start;
}

.nav-item {
  height: 40px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #111;
  background-color: lavender;
}

.nav-links {
  color: rgba(var(--textColor), 0.9);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 1rem;
  height: 100%;
}

.fa-bars {
  color: rgba(var(--textColor), 0.9);
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 40px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: linear-gradient(90deg, rgb(39, 176, 255) 0%,rgb(0, 232, 236) 100%);
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
    margin-bottom: 50px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(5%, 0%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 20%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: rgba(var(--textColor), 0.9);
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}
:root{
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline{
    background-color: transparent;
    color: rgb(10, 10, 10);
    padding: 8px 20px;
    border: 1px solid rgb(10, 10, 10);
    transition: all 0.3s ease-out;
}

.btn--medium{
    padding: 2px 16px;
    font-size: 18px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--mobile{
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--wide{
    padding: 12px 64px;
    font-size: 20px;
}

.btn--large:hover, .btn--medium:hover, .btn--mobile:hover{
    transition: all 0.3s ease-out;
    background: #111;
    color: #fff;
    border: #fff;
}

.btn--wide:hover{
    color: #fff;
    background-color: #111;
    transition: all 0.2s ease-out;
}

.blue{
    background-color: #276afb;
    color: #fff;
    border: none;
}

.red{
    background-color: #f00946;
    color: #fff;
    border: none;
}

.primary {
    background-color: #242424;
    color: #fff;
    border: none;
}

.primary:hover{
    background-color: #fff;
    color: #242424;
    border: none;
}

.green{
    background-color: #25ce4a;
    color: #fff;
    border: none;
}

.green:hover{
    background-color: #fff;
    color: #25ce4a;
    border: none;
}

.btn-link{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
}
.search-container{
    display: flex;
    /* place-content: center space-evenly; */
    justify-content: flex-end;
    align-items: center;
    margin-right: 45px;
    margin-top: 25px;
    height: 50px;
}
.search-bar{
    height: 40px;
    margin-top: 5px;
    margin-right: 55px;
    padding-left: 10px;
    display: block;
    border-radius: 2px;
}

.search-bar::-webkit-input-placeholder {
    color: #595959;
    font-size: 12px;
}

.search-bar:-ms-input-placeholder {
    color: #595959;
    font-size: 12px;
}

.search-bar::-ms-input-placeholder {
    color: #595959;
    font-size: 12px;
}

.search-bar::placeholder {
    color: #595959;
    font-size: 12px;
}

.hover {
    cursor: pointer;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  .filter-inputs {
    margin-bottom: 0.5rem;
    width: 300px;
    margin-right: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding-bottom: 2rem;
}

.filter-label {
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 6px;
    color: #fff;
    padding-right: .5rem;
}
  
  .filter-input {
    display: block;
    font-size: 1rem;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
}
.form-container {
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 1000px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 600px;
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-content-left {
    background: linear-gradient(
      90deg,
      rgb(250, 244, 242) 0%,
      rgb(0, 232, 236) 100%
    );
    border-radius: 10px 0 0 10px;
    position: relative;
}

  
.form-content-right {
    border-radius: 0 10px 10px 0;
    position: relative;
    background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

.form-inputs {
    margin-bottom: 0.5rem;
    width: 80%;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
}
  
  .form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
}

 textarea {
    height: 160px;
}

.form h1 {
    font-size: 1rem;
    text-align: start;
    width: 80%;
    margin-bottom: 1rem;
    color: #fff;
}

.form-input-btn {
    width: 80%;
    height: 50px;
    margin-top: 10px;
    border-radius: 2px;
    background: linear-gradient(
      90deg,
      rgb(39, 176, 255) 0%,
      rgb(0, 232, 236) 100%
    );
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
  }
  
  .form-input-btn:hover {
    cursor: pointer;
    background: linear-gradient(
      90deg,
      rgb(39, 143, 255) 0%,
      rgb(12, 99, 250) 100%
    );
    transition: all 0.4s ease-out;
  }
.footer-container {
   background: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #111;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 24px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #111;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input:-ms-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::-ms-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 5px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
  }
  
  .social-logo {
    color: #111;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #111;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
