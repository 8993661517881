.search-container{
    display: flex;
    /* place-content: center space-evenly; */
    justify-content: flex-end;
    align-items: center;
    margin-right: 45px;
    margin-top: 25px;
    height: 50px;
}
.search-bar{
    height: 40px;
    margin-top: 5px;
    margin-right: 55px;
    padding-left: 10px;
    display: block;
    border-radius: 2px;
}

.search-bar::placeholder {
    color: #595959;
    font-size: 12px;
}

.hover {
    cursor: pointer;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  .filter-inputs {
    margin-bottom: 0.5rem;
    width: 300px;
    margin-right: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding-bottom: 2rem;
}

.filter-label {
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 6px;
    color: #fff;
    padding-right: .5rem;
}
  
  .filter-input {
    display: block;
    font-size: 1rem;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
}